import { z } from 'zod';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { CarStockBodyTypeEnum } from '~/gql/generated/graphql';
import type { StateCreatorWithImmer } from '~/types/zustand';

export const searchCreateSchema = z.object({
  freeText: z.string().optional(),
  makeCode: z.string().optional(),
  carModelCode: z.string().optional(),
  bodyType: z
    .union([z.literal(''), z.null(), z.nativeEnum(CarStockBodyTypeEnum)])
    .optional()
    .transform((v) => (v ? v : null)),
  shift: z.string().optional(),
  yearTo: z.string().optional(),
  yearFrom: z.string().optional()
});

type SearchManageSliceType = {
  search: z.output<typeof searchCreateSchema>;
  setSearch: (searchParam: SearchManageSliceType['search']) => void;
  closeSearchModal: () => void;
  setCloseSearchModal: (closeSearchModal: SearchManageSliceType['closeSearchModal']) => void;
};

const createSearchManageIdsSlice: StateCreatorWithImmer<SearchManageSliceType> = immer((set) => ({
  search: {
    bodyType: null
  },
  setSearch: (searchParam) =>
    set((state) => {
      state.search = searchParam;
    }),
  closeSearchModal: () => {},
  setCloseSearchModal: (closeSearchModal) =>
    set((state) => {
      state.closeSearchModal = closeSearchModal;
    })
}));

type SearchManageStore = SearchManageSliceType;
export const useSearchManageStore = create<SearchManageStore>((...a) => ({
  ...createSearchManageIdsSlice(...a)
}));
