import type { VariantProps } from 'tailwind-variants';
import { tv } from 'tailwind-variants';

const labelVariants = tv({
  base: 'rounded-[4px] px-3 py-[2px] text-[10px] text-white',
  variants: {
    labelType: {
      required: 'bg-notice',
      optional: 'bg-color-text-sub'
    }
  },
  defaultVariants: {
    labelType: 'optional'
  }
});

type Props = {
  text?: string;
  required?: boolean;
  name: string;
  showSubLabel: boolean;
} & VariantProps<typeof labelVariants>;

export const Label = ({ text, required, name, showSubLabel }: Props) => {
  return (
    <div className='flex items-center gap-3'>
      {showSubLabel && text && (
        <span className={labelVariants({ labelType: required ? 'required' : 'optional' })}>
          {required ? '必須' : '任意'}
        </span>
      )}
      <label className='text-sm text-color-text-sub' htmlFor={name}>
        {text}
      </label>
    </div>
  );
};
