import liff from '@line/liff';
import { useLocation, useNavigate } from '@remix-run/react';
import { useLoginQueryParameter } from './useLoginQueryParameter';
import { useClientEnvContext, useLiffContext } from '~/providers';
import { lineFriendUrl } from '~/constants/line';

type PageTransitionType = 'replace' | 'push';

type Props = {
  redirectPath?: string;
};

export const useLineLoginMethods = (props: Props) => {
  const { redirectPath: designatedRedirectPath } = props;
  const location = useLocation();
  const currentPath = `${location.pathname}${location.search}`;
  const redirectPath = designatedRedirectPath || currentPath;
  const { loginQueryParameter } = useLoginQueryParameter({ redirectPath, loginSourcePath: currentPath });
  const callbackPath = `/callback?${loginQueryParameter}`;
  const { liffId } = useLiffContext();
  const { environment } = useClientEnvContext();
  const navigate = useNavigate();

  const pageTransition = ({ url, type }: { url: string; type?: PageTransitionType }) => {
    if (type === 'replace') {
      window.location.replace(url);
    } else {
      window.location.href = url;
    }
  };

  const liffLogin = (pageTransitionType?: PageTransitionType) => {
    pageTransition({ url: `https://liff.line.me/${liffId}${callbackPath}`, type: pageTransitionType });
  };

  const browserLogin = () => {
    const currentOrigin = window.location.origin;
    liff.login({ redirectUri: `${currentOrigin}${callbackPath}` });
  };

  const lineUrlScheme = () => {
    const os = liff.getOS();
    switch (os) {
      case 'android':
        return `intent://liff.line.me/${liffId}${callbackPath}#Intent;scheme=https;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=jp.naver.line.android;end`;
      case 'ios':
        return `line://app/${liffId}${callbackPath}`;
      default:
        return `https://liff.line.me/${liffId}${callbackPath}`;
    }
  };

  const lineUrlSchemeLogin = () => {
    const url = lineUrlScheme();
    pageTransition({ url, type: 'push' });
  };

  const lineAddFriend = (pageTransitionType?: PageTransitionType) => {
    const url = lineFriendUrl(environment);
    pageTransition({ url, type: pageTransitionType });
  };

  const normalLogin = (pageTransitionType?: PageTransitionType) => {
    if (liff.isInClient()) {
      navigate(callbackPath, { replace: pageTransitionType === 'replace' });
      return;
    }

    if (liff.getOS() === 'web') {
      browserLogin();
      return;
    }

    liffLogin(pageTransitionType);
  };

  return { liffLogin, browserLogin, lineUrlSchemeLogin, lineAddFriend, normalLogin };
};
