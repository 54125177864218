import { type LoaderFunctionArgs, type MetaFunction } from '@remix-run/cloudflare';
import { useLoaderData } from '@remix-run/react';
import queryString from 'query-string';
import type { Client } from 'urql';
import { z } from 'zod';
import { StandardError } from '~/components/errors';
import { Layout } from '~/components/layouts';
import { CarsSearch } from '~/components/pages';
import { graphql } from '~/gql/generated';
import { CarSalesStatusEnum } from '~/gql/generated/graphql';
import type {
  CarStockSortTypeEnum,
  CarStocksSearchPageQuery,
  CarStocksSearchPageQueryVariables
} from '~/gql/generated/graphql';
import { ssrClient } from '~/graphql/client';
import { getEnvironmentVariables } from '~/infrastructure/environmentVariables';
import { loaderErrorHandle } from '~/infrastructure/errorHandle';
import { searchCreateSchema } from '~/store/search-store';
import { mergeMetaTags } from '~/utils/meta';
import { safeParser } from '~/utils/parser';

const FIRST_FETCH_CAR_STOCKS_COUNT = 18;

export const carStocksSearchPageQueryDocument = graphql(`
  query CarStocksSearchPage(
    $first: Int
    $after: String
    $salesStatus: CarSalesStatusEnum
    $bodyType: CarStockBodyTypeEnum
    $makeCode: String
    $carModelCode: String
    $shift: String
    $yearFrom: String
    $yearTo: String
    $freeText: String
    $sortType: CarStockSortTypeEnum
    $hasVideo: Boolean
    $includeTotalCount: Boolean!
  ) {
    carStocks(
      first: $first
      after: $after
      salesStatus: $salesStatus
      bodyType: $bodyType
      makeCode: $makeCode
      carModelCode: $carModelCode
      shift: $shift
      yearFrom: $yearFrom
      yearTo: $yearTo
      freeText: $freeText
      sortType: $sortType
      hasVideo: $hasVideo
    ) {
      edges {
        node {
          ...CarsSearch_carStock
        }
      }
      totalCount @include(if: $includeTotalCount)
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

const formQuery = graphql(`
  query makerAndBodyTypeOption {
    formOption {
      id
      carStockSearchMakerOption {
        id
        value
        label
      }
      carStockSearchBodyTypeOption {
        id
        value
        label
      }
    }
  }
`);

type CarStockType = NonNullable<
  NonNullable<NonNullable<CarStocksSearchPageQuery['carStocks']['edges']>[number]>['node']
>;

const salesStatsSchema = z
  .nativeEnum(CarSalesStatusEnum)
  .nullish()
  .transform((val) => val ?? CarSalesStatusEnum.OnSale);

export const variablesFromSearchParams = (
  searchParams: URLSearchParams,
  first: number
): CarStocksSearchPageQueryVariables => {
  const salesStatus = salesStatsSchema.parse(searchParams.get('status'));
  const sortType = searchParams.get('sortType') as CarStockSortTypeEnum;
  const searchSchema = safeParser(searchCreateSchema, queryString.parse(searchParams.toString()));

  return {
    salesStatus,
    sortType,
    first,
    includeTotalCount: salesStatus === CarSalesStatusEnum.OnSale,
    ...searchSchema
  };
};

export const loader = async ({ context, request }: LoaderFunctionArgs) => {
  const env = getEnvironmentVariables(context);
  const url = new URL(request.url);
  const searchParams = url.searchParams;

  const variables = variablesFromSearchParams(searchParams, FIRST_FETCH_CAR_STOCKS_COUNT);
  const graphqlClient = ssrClient({ baseUrl: env.graphqlBaseUrl });
  const res = await graphqlClient.query(carStocksSearchPageQueryDocument, variables);
  const hasError = !!res.error;

  const overrideTitle = await buildTitle(graphqlClient, searchParams);
  if (hasError) {
    throw loaderErrorHandle(res.operation.context.url, res.error!);
  }

  return {
    carStocks: (res.data?.carStocks?.edges?.map((edge) => edge?.node).filter((node) => !!node) || []) as CarStockType[],
    totalCount: res.data?.carStocks.totalCount,
    hasNextPage: res.data?.carStocks?.pageInfo?.hasNextPage || false,
    endCursor: res.data?.carStocks?.pageInfo?.endCursor,
    variables: variables,
    hasError: hasError,
    overrideTitle
  };
};

const buildTitle = async (client: Client, searchParams: URLSearchParams) => {
  const { data } = await client.query(formQuery, {});
  const makerOption = data?.formOption.carStockSearchMakerOption || [];
  const bodyTypeOption = data?.formOption.carStockSearchBodyTypeOption || [];
  const searchSchema = safeParser(searchCreateSchema, queryString.parse(searchParams.toString()));
  const makeCode = searchSchema?.makeCode;
  const bodyType = searchSchema?.bodyType;

  const results: string[] = [];

  if (makeCode) {
    const makerLabel = makerOption.find(({ value }) => value === makeCode)?.label;
    if (makerLabel) results.push(makerLabel);
  }
  if (bodyType) {
    const bodyTypeLabel = bodyTypeOption.find(({ value }) => value === bodyType)?.label;
    if (bodyTypeLabel) results.push(bodyTypeLabel);
  }
  return results.join(' - ');
};

export const meta: MetaFunction<typeof loader> = ({ data, matches }) => {
  const BRAND = '車の通販ならバディカダイレクト';
  const title = data?.overrideTitle
    ? `${data.overrideTitle}の中古車一覧 - ${BRAND}`
    : `バディカダイレクトの中古車一覧 - ${BRAND}`;
  const metaTags = [{ title: title }, { name: 'title', content: title }, { property: 'og:title', content: title }];

  return mergeMetaTags({ metaTags, matches });
};

export default function SearchRoute() {
  const data = useLoaderData<typeof loader>();

  if (data.hasError) {
    return (
      <Layout>
        <StandardError />
      </Layout>
    );
  }

  return (
    <Layout>
      <CarsSearch
        carStocks={data.carStocks}
        totalCount={data.totalCount}
        firstHasNextCarStocks={data.hasNextPage}
        firstEndCursor={data.endCursor}
      />
    </Layout>
  );
}
