import type { ReactNode } from 'react';
import { useCallback } from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';
import { FormProvider } from 'react-hook-form';

type FormWrapperProps = {
  children: ReactNode;
};

type Props<T extends FieldValues, U extends FieldValues | undefined = undefined> = {
  methods: UseFormReturn<T, unknown, U>;
};
export const useFormProvider = <T extends FieldValues, U extends FieldValues | undefined = undefined>({
  methods
}: Props<T, U>) => {
  const FormWrapper = useCallback(
    ({ children }: FormWrapperProps) => <FormProvider {...methods}>{children}</FormProvider>,
    [methods]
  );

  return { FormWrapper };
};
