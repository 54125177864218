import { useLocation } from '@remix-run/react';
import { useEffect, useRef, useState, type FunctionComponent } from 'react';
import { useClient, useQuery } from 'urql';
import { CarCardGroup, ConsultationBanner } from '~/components/shared';
import { NewNotificationBanner } from '~/components/shared/banners';
import { graphql } from '~/gql/generated';
import { CarSalesStatusEnum } from '~/gql/generated/graphql';
import type { CarStockBodyTypeEnum, CarsListComponentQuery } from '~/gql/generated/graphql';
import { useAuth } from '~/hooks';
import { useDeliveryAddressContext, useInfiniteScrollContext } from '~/providers';
import styles from '~/styles/page/cars/carsShow/cars-list.module.css';

const FETCH_CAR_STOCKS_COUNT = 20;
const blockClass = 'cars-list';

type CarStockType = NonNullable<NonNullable<NonNullable<CarsListComponentQuery['carStocks']['edges']>[number]>['node']>;

const carsListComponentQueryDocument = graphql(`
  query CarsListComponent(
    $prefectureCode: Int
    $first: Int
    $after: String
    $salesStatus: CarSalesStatusEnum
    $bodyType: CarStockBodyTypeEnum
  ) {
    carStocks(first: $first, after: $after, salesStatus: $salesStatus, bodyType: $bodyType) {
      edges {
        node {
          id
          landTransportCost(prefectureCode: $prefectureCode)
          ...CarCardGroup_carStock
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

type Props = {
  carStockId: string;
  bodyType?: CarStockBodyTypeEnum;
};

export const CarsList: FunctionComponent<Props> = ({ carStockId, bodyType }) => {
  const { prefectureCode } = useDeliveryAddressContext();
  const variables = { prefectureCode, first: 18, salesStatus: CarSalesStatusEnum.OnSale, bodyType };
  const [{ data, fetching }] = useQuery({ query: carsListComponentQueryDocument, variables: variables });
  const [carStocks, setCarStocks] = useState<CarStockType[]>([]);
  const [endCursor, setEndCursor] = useState<string | null | undefined>('');
  const [hasNextCarStocks, setHasNextCarStocks] = useState(true);
  const groupedCarStocks = carStocks.reduce<CarStockType[][]>((acc, carStock, index) => {
    if (index % 5 === 0) {
      return [...acc, [carStock]];
    }
    return [...acc.slice(0, -1), [...acc[acc.length - 1], carStock]];
  }, []);
  const { user } = useAuth();
  const favoritedCarStocks = user?.favoritedCarStocks || [];
  const favoritedCarStockIds = favoritedCarStocks.map((carStock) => carStock.id);
  const observerTarget = useRef(null);
  const [initializing, setInitializing] = useState(true);
  const client = useClient();
  const [isScrolledBottom, setIsScrolledBottom] = useState(false);
  const location = useLocation();
  const { infiniteScroll, addScrolledCursor } = useInfiniteScrollContext();

  useEffect(() => {
    if (fetching) return;

    const firstCarStocks = (data?.carStocks?.edges?.
    map((edge) => edge?.node).
    filter((node) => !!node).
    filter((node) => node?.id !== carStockId) || []) as CarStockType[];
    const firstHasNextCarStocks = data?.carStocks?.pageInfo?.hasNextPage || false;
    const firstEndCursor = data?.carStocks?.pageInfo?.endCursor;

    setCarStocks(firstCarStocks);
    setEndCursor(firstEndCursor);
    setHasNextCarStocks(firstHasNextCarStocks);
    initializeCarStocks();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, fetching]);

  const initializeCarStocks = async () => {
    const targetCursors = infiniteScroll[location.key]?.cursors || [];

    if (targetCursors.length === 0) {
      setInitializing(false);
      return;
    }

    targetCursors.forEach(async (cursor, index) => {
      const variables = {
        first: FETCH_CAR_STOCKS_COUNT,
        salesStatus: CarSalesStatusEnum.OnSale,
        after: endCursor,
        prefectureCode: prefectureCode,
        bodyType
      };
      const result = await client.query(carsListComponentQueryDocument, variables);
      if (result.error) return;

      const resultCarStocks = (result.data?.carStocks?.edges?.
      map((edge) => edge?.node).
      filter((node) => !!node).
      filter((node) => node?.id !== carStockId) || []) as CarStockType[];
      setCarStocks((prev) => [...prev, ...resultCarStocks]);

      if (index === targetCursors.length - 1) {
        const resultHasNextPage = result.data?.carStocks?.pageInfo?.hasNextPage || false;
        const resultEndCursor = result.data?.carStocks?.pageInfo?.endCursor;
        setHasNextCarStocks(resultHasNextPage);
        setEndCursor(resultEndCursor);
      }
    });
    setInitializing(false);
    setTimeout(() => {
      const elementId = infiniteScroll[location.key]?.elementId;
      if (!elementId) return;
      const targetElement = document.getElementById(elementId);
      if (!targetElement) return;
      targetElement.scrollIntoView({ block: 'center' });
    }, 100);
  };

  const fetchNextCarStocks = async () => {
    if (!hasNextCarStocks) {
      return;
    }
    if (!isScrolledBottom) return;

    const currentEndCursor = endCursor;
    const variables = {
      first: FETCH_CAR_STOCKS_COUNT,
      salesStatus: CarSalesStatusEnum.OnSale,
      after: endCursor,
      prefectureCode: prefectureCode,
      bodyType
    };
    const result = await client.query(carsListComponentQueryDocument, variables);
    if (result.error) {
      setIsScrolledBottom(false);
      return;
    }
    const resultCarStocks = (result.data?.carStocks?.edges?.
    map((edge) => edge?.node).
    filter((node) => !!node).
    filter((node) => node?.id !== carStockId) || []) as CarStockType[];
    const resultHasNextPage = result.data?.carStocks?.pageInfo?.hasNextPage || false;
    const resultEndCursor = result.data?.carStocks?.pageInfo?.endCursor;
    setCarStocks((prev) => [...prev, ...resultCarStocks]);
    setHasNextCarStocks(resultHasNextPage);
    setEndCursor(resultEndCursor);

    if (currentEndCursor) {
      addScrolledCursor(location.key, currentEndCursor);
    }

    setIsScrolledBottom(false);
  };

  useEffect(() => {
    if (!isScrolledBottom) return;
    fetchNextCarStocks();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScrolledBottom]);

  useEffect(() => {
    if (initializing) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if (hasNextCarStocks) {
            setIsScrolledBottom(true);
          }
        }
      },
      { threshold: 1.0 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializing]);

  return (
    <div className={styles[blockClass]}>
      <p className={styles[`${blockClass}__title`]}>もっと見つける</p>
      {groupedCarStocks[0] &&
      <CarCardGroup carStocks={groupedCarStocks[0]} favoritedCarStockIds={favoritedCarStockIds} />
      }
      <ConsultationBanner />
      {groupedCarStocks[1] &&
      <CarCardGroup carStocks={groupedCarStocks[1]} favoritedCarStockIds={favoritedCarStockIds} />
      }
      {groupedCarStocks.slice(2).map((carStocks, index) =>
      <CarCardGroup key={index} carStocks={carStocks} favoritedCarStockIds={favoritedCarStockIds} />
      )}
      {!hasNextCarStocks && <NewNotificationBanner />}
      <div ref={observerTarget}> {hasNextCarStocks && <div className={styles[`${blockClass}__loader`]} />}</div>
    </div>);

};