import type { FieldValues, Path, UseFormReturn } from 'react-hook-form';
import { FieldWrapper } from './FieldWrapper';
import { Input } from './Input';

type Props<U extends FieldValues> = {
  label?: string;
  name: Path<U>;
  form: UseFormReturn<U, unknown, any>;
  required?: boolean;
  placeholder?: string;
  showSubLabel?: boolean;
};

export const InputText = <T extends FieldValues>({
  label,
  name,
  form,
  required,
  placeholder,
  showSubLabel = true
}: Props<T>) => {
  return (
    <FieldWrapper
      name={name}
      label={label}
      errorMessage={form.formState.errors[name]?.message?.toString()}
      required={required}
      showSubLabel={showSubLabel}
    >
      <Input register={form.register(name)} id={name} placeholder={placeholder} />
    </FieldWrapper>
  );
};
