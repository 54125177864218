import * as React from 'react';
import { type FieldError } from 'react-hook-form';
import { Error } from './Error';
import { Label } from './Label';
import type { VariantProps } from 'tailwind-variants';
import { tv } from 'tailwind-variants';

const wrapperVariants = tv({
  base: 'grid h-full gap-2',
  variants: {
    arrangeType: {
      normal: 'grid-rows-input-normal',
      none: ''
    }
  },
  defaultVariants: {
    arrangeType: 'normal'
  }
});
type FieldWrapperProps = {
  name: string;
  label?: string;
  children: React.ReactNode;
  required?: boolean;
  errorMessage?: FieldError['message'];
  showSubLabel: boolean;
} & VariantProps<typeof wrapperVariants>;

export const FieldWrapper = (props: FieldWrapperProps) => {
  const { label, children, name, required, errorMessage, arrangeType, showSubLabel } = props;
  return (
    <div className={wrapperVariants({ arrangeType })}>
      <Label name={name} required={required} text={label} showSubLabel={showSubLabel} />
      {children}
      <Error errorMessage={errorMessage} />
    </div>
  );
};
