import { zodResolver } from '@hookform/resolvers/zod';
import { useFormContext } from 'react-hook-form';
import type { z } from 'zod';
import { useFormProvider } from '~/assets/scss/_/hooks/use-form-provider';
import { useTypedForm } from '~/hooks/useTypedForm';
import { searchCreateSchema } from '~/store/search-store';
import { useSearchManage } from './useSearchManage';
import { useMemo } from 'react';
import { useQuery } from 'urql';
import { graphql } from '~/gql/generated';

type CreateSchema = z.output<typeof searchCreateSchema>;
type InputSchema = z.input<typeof searchCreateSchema>;

const formQuery = graphql(`
  query formOptionCarStockSearch {
    formOption {
      id
      carStockSearchMakerOption {
        id
        value
        label
      }
      carStockSearchBodyTypeOption {
        id
        value
        label
      }
    }
  }
`);

export const useNewSearchForm = () => {
  const { search } = useSearchManage();
  const form = useTypedForm<InputSchema, CreateSchema>({
    resolver: zodResolver(searchCreateSchema),
    defaultValues: search
  });

  const [result] = useQuery({
    query: formQuery
  });

  const makers = useMemo(() => result.data?.formOption.carStockSearchMakerOption || [], [result.data]);
  const bodyTypes = useMemo(() => result.data?.formOption.carStockSearchBodyTypeOption || [], [result.data]);

  const { FormWrapper } = useFormProvider({ methods: form });
  return {
    FormWrapper,
    form,
    isFetching: result.fetching,
    makers,
    bodyTypes
  };
};

export const useNewSearchFormContext = () => {
  return {
    ...useFormContext<InputSchema, unknown, CreateSchema>()
  };
};
