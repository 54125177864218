import { Link } from '@remix-run/react';
import type { FunctionComponent } from 'react';
import styles from '~/styles/page/selling/review-section.module.css';
import { ConversionButton } from './ConversionButton';

export const ReviewSection: FunctionComponent = () => {
  const reviews = [
  {
    name: '福井 K.H',
    icon: '/images/reviews/img_240229.jpg',
    text: 'この車は急を用して購入したこともあり、当初はそれほど思い入れがなかったのですが、流石に新車から195000キロも走ったので、たくさんの思い出が詰まっています。（仕事で失敗した時、何度も車内で泣きました笑）こいつは、どんな...'
  },
  {
    name: '石川 Y.S',
    icon: '/images/reviews/img_0228_sn.jpg',
    text: '社会人になって5年乗り続けたフィットの車検が近づき、夫娘でずっと乗ってみたかったオデッセイを探していたところ、条件のいい一台を見つけるも、下取り価格は到底希望額に近づかずで諦めかけてました...そんな時にバディカダイ...'
  },
  {
    name: '愛知県 M・K',
    icon: '/images/reviews/img_1229_mk.jpg',
    text: '子供が生まれるため、現在の車を売却し、スライドドア付きの軽自動車を購入することを考え、その際にバディカさんのYoutubeチャンネルを発見しました。中野さんから高く売り、安く買うための知識を得ましたが、「この際全てをバディカ...'
  }];


  return (
    <div>
      <div className={styles.header}>
        <p className={styles.title}>査定サービス利用者の声</p>
        <Link to='/reviews/' className={styles.link}>
          もっと見る
        </Link>
      </div>
      <div className={styles.reviews}>
        {reviews.map((review, index) =>
        <div className={styles.item} key={index}>
            <div className={styles.header}>
              <img src={review.icon} alt='reviewer_icon' className={styles.icon} />
              <div>
                <p className={styles.name}>{review.name}様</p>
              </div>
            </div>
            <div className={styles.text}>
              {review.text.split(/\n/).map((line, index) =>
            <p key={index}>{line}</p>
            )}
            </div>
          </div>
        )}
      </div>

      <div className={styles['bottom-button']}>
        <ConversionButton text='無料：愛車査定はこちら' />
      </div>
    </div>);

};