import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import type { StateCreatorWithImmer } from '~/types/zustand';

type TrackingManageSliceType = {
  tracking: Record<string, unknown>;
  setTracking: (trackingParam: TrackingManageSliceType['tracking']) => void;
  via: string[];
  setTrackingVia: (via: string[]) => void;
};

const PARAM_IGNORES = [
  'code',
  'state',
  'liff.state',
  'liffClientId',
  'redirectPath',
  'liff.referrer',
  'liffRedirectUri',
  'redirectExternal',
  'redirectUrl'
];
const createTrackingManageIdsSlice: StateCreatorWithImmer<TrackingManageSliceType> = immer((set) => ({
  tracking: {},
  setTracking: (trackingParam) =>
    set((state) => {
      state.tracking = { ...state.tracking, ...trackingParam };
      Object.keys(trackingParam).forEach((k) => {
        if (PARAM_IGNORES.includes(k)) {
          delete state.tracking[k];
        }
      });
    }),
  via: [],
  setTrackingVia: (via) =>
    set((state) => {
      const newVia = [...new Set([...state.via, ...via])].flat();
      state.via = newVia;
      state.tracking = { ...state.tracking, via: newVia };
    })
}));

const EXPIRY_DURATION = 3 * 24 * 60 * 60 * 1000; // 3日間のミリ秒
type TrackingManageStore = TrackingManageSliceType;
export const useTrackingManageStore = create<TrackingManageStore>()(
  persist(
    (...a) => ({
      ...createTrackingManageIdsSlice(...a)
    }),
    {
      name: 'tracking-manage-store',
      version: Date.now(),
      migrate: (persistedState, version) => {
        const expiryTimestamp = version + EXPIRY_DURATION;
        const now = Date.now();
        if (now > expiryTimestamp) {
          console.log('Clearing persisted state');
          return {};
        }
        return persistedState;
      }
    }
  )
);
