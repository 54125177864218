import { useNavigate } from '@remix-run/react';
import type { FunctionComponent } from 'react';
import { useAnalytics } from '~/hooks';
import styles from '~/styles/page/selling/conversion-button.module.css';

type Props = {
  text: string;
};
export const ConversionButton: FunctionComponent<Props> = ({ text }) => {
  const { sendTrackEvent } = useAnalytics();
  const navigate = useNavigate();

  const handleClick = () => {
    sendTrackEvent('click_selling_conversion_button');
    navigate('/add_line_friend?inflow_from=selling_aishameter');
  };

  return (
    <button className={styles.button} onClick={handleClick}>
      {text}
    </button>);

};