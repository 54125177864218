import { useEffect, type FunctionComponent } from 'react';
import styles from '~/styles/shared/car-search-modal.module.css';
import { Modal } from '..';
import { NewSearch } from '../NewSearch/NewSearch';
import { useSearchManage } from '../NewSearch/useSearchManage';

const blockClass = 'car-search-modal';

type Props = {
  opened: boolean;
  close: () => void;
};

export const CarSearchModal: FunctionComponent<Props> = (props) => {
  const { opened, close } = props;
  const { setCloseSearchModal } = useSearchManage();

  useEffect(() => {
    if (opened) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [opened]);

  useEffect(() => {
    setCloseSearchModal(close);
  }, [setCloseSearchModal, close]);

  return (
    <Modal
      isOpen={opened}
      className={styles[blockClass]}
      onRequestClose={close}
      style={{
        overlay: {
          backgroundColor: 'rgba(255, 255, 255)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          zIndex: 201
        }
      }}>

      {opened &&
      <>
          <button onClick={close} aria-label='Close Modal' className='flex w-full justify-end text-lg font-bold '>
            ✕
          </button>
          <NewSearch />
        </>
      }
    </Modal>);

};