import type { ZodTypeAny, SafeParseReturnType, z } from 'zod';

export const safeParser = <T extends ZodTypeAny>(zodTypeAny: T, data: unknown) => {
  const safeParseReturn: SafeParseReturnType<unknown, z.infer<typeof zodTypeAny>> = zodTypeAny.safeParse(data);
  if (!safeParseReturn.success) {
    console.warn(safeParseReturn.error.message);
    return null;
  }
  const parsedData = safeParseReturn.data;
  return parsedData;
};
