import { useQuery } from 'urql';
import { InputText } from '../Form/InputText';
import { Select } from '../Form/Select';
import { useNewSearchForm, useNewSearchFormContext } from './useNewSearchForm';
import { graphql } from '~/gql/generated';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { NewSearchSubmitButton } from './NewSearchSubmitButton';

const YEARS = (() => {
  const currentYear = new Date().getFullYear();
  const MIN_YEAR = 1980;
  const years = [];
  for (let year = MIN_YEAR; year <= currentYear; year++) {
    years.push({ id: year.toString(), value: year.toString(), label: `${year}年` });
  }
  return years;
})();

export const NewSearch = () => {
  const { form, FormWrapper, makers, bodyTypes, isFetching } = useNewSearchForm();

  if (isFetching) return null;
  return (
    <FormWrapper>
      <div className='grid gap-5'>
        <InputText name='freeText' form={form} label='フリーワード' showSubLabel={false} placeholder='ハイルーフ' />
        <div className='grid grid-cols-2 gap-2'>
          <Select
            name='makeCode'
            form={form}
            label='メーカー'
            showSubLabel={false}
            options={makers}
            handleChange={(e) => {
              form.reset({ carModelCode: '' });
              form.setValue('makeCode', e.target.value);
            }}
          />
          <CarNameSelect />
        </div>
        <div className='grid grid-cols-2 gap-2'>
          <Select name='bodyType' form={form} label='車両タイプ' showSubLabel={false} options={bodyTypes} />
          <Select
            name='shift'
            form={form}
            label='シフト'
            showSubLabel={false}
            options={[
              { label: 'AT/CVT', value: 'AT' },
              { label: 'MT', value: 'MT' }
            ]}
          />
        </div>
        <div className='flex items-center gap-2'>
          <Select
            name='yearFrom'
            form={form}
            label='年式で探す'
            showSubLabel={false}
            options={YEARS}
            handleChange={(e) => {
              const yearFrom = e.target.value;
              const yearTo = form.getValues('yearTo') || '';
              if (checkYearFrom({ yearFrom, yearTo })) {
                return form.setValue('yearFrom', yearFrom);
              }
              alert('年式は終了年よりも小さい値を選択してください');
              form.setValue('yearFrom', '');
            }}
          />
          <span className='translate-y-[15px] items-center whitespace-nowrap text-lg'>~</span>
          <Select
            name='yearTo'
            form={form}
            showSubLabel={false}
            options={[...YEARS].reverse()}
            handleChange={(e) => {
              const yearFrom = form.getValues('yearFrom') || '';
              const yearTo = e.target.value;
              if (checkYearTo({ yearFrom, yearTo })) {
                return form.setValue('yearTo', yearTo);
              }
              alert('年式は開始年よりも大きい値を選択してください');
              form.setValue('yearTo', '');
            }}
          />
        </div>
        <NewSearchSubmitButton />
      </div>
    </FormWrapper>
  );
};

type YearProps = {
  yearFrom: string;
  yearTo: string;
};
const checkYearFrom = ({ yearFrom, yearTo }: YearProps): boolean => {
  if (!yearFrom) return true;
  if (!yearTo) return true;
  return Number(yearFrom) <= Number(yearTo);
};

const checkYearTo = ({ yearFrom, yearTo }: YearProps): boolean => {
  if (!yearTo) return true;
  if (!yearFrom) return true;
  return Number(yearTo) >= Number(yearFrom);
};

const formCarStockQuery = graphql(`
  query formCarStockQuery($makerId: ID!) {
    carNameOption(makerId: $makerId) {
      id
      carNameOptions {
        id
        value
        label
      }
    }
  }
`);

const CarNameSelect = () => {
  const form = useNewSearchFormContext();
  const makerId = useWatch({ control: form.control, name: 'makeCode' }) || '';
  const [result] = useQuery({
    query: formCarStockQuery,
    variables: { makerId },
    pause: !makerId,
    requestPolicy: 'network-only'
  });
  const carNameOptions = useMemo(() => result.data?.carNameOption.carNameOptions || [], [result.data]);
  if (result.fetching) return null;
  return (
    <Select name='carModelCode' form={form} label='車種' showSubLabel={false} options={makerId ? carNameOptions : []} />
  );
};
