import type { FunctionComponent } from 'react';
import styles from '~/styles/page/selling/top.module.css';

export const Top: FunctionComponent = () => {
  return (
    <div className={styles.top}>
      <span className={styles.free}>無料</span>
      <p className={styles.title}>愛車買取査定</p>
      <p className={styles.description}>
        LINEとAIで簡単！
        <br />
        オンライン完結の愛車買取
      </p>
      <div className={styles.person}>
        <img src={'/images/selling/img_message.png'} alt='person icon' />
      </div>
      <ul className={styles.list}>
        <li className={styles.item}>
          <div className={styles.icon}>
            <img src={'/images/selling/message_icon_2.svg'} alt='icon2' />
          </div>
          <p className={styles.text}>電話一切なし</p>
        </li>

        <li className={styles.item}>
          <div className={styles.icon}>
            <img src={'/images/selling/top_icon_photo.svg'} alt='car_photo' />
          </div>
          <p className={styles.text}>
            写真だけで
            <br />
            カンタン
          </p>
        </li>

        <li className={styles.item}>
          <div className={styles.icon}>
            <img src={'/images/selling/top_icon_smartphone.svg'} alt='smartphone_icon' />
          </div>
          <p className={styles.text}>LINEだけで完結</p>
        </li>
      </ul>
    </div>);

};