import type { FieldValues, Path, UseFormReturn } from 'react-hook-form';
import { FieldWrapper } from './FieldWrapper';
import type { OptionsType } from './type';

type Props<U extends FieldValues> = {
  label?: string;
  name: Path<U>;
  form: UseFormReturn<U, unknown, any>;
  required?: boolean;
  options: OptionsType;
  isDefault?: boolean;
  showSubLabel?: boolean;
  handleChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  className?: string;
};

export const Select = <T extends FieldValues>({
  label,
  name,
  form,
  required,
  options,
  isDefault = true,
  showSubLabel = true,
  handleChange
}: Props<T>) => {
  return (
    <FieldWrapper
      name={name}
      label={label}
      errorMessage={form.formState.errors[name]?.message?.toString()}
      required={required}
      showSubLabel={showSubLabel}
    >
      <div className='flex flex-wrap gap-x-3 gap-y-2'>
        <select
          {...form.register(name, {
            onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
              handleChange?.(e);
            }
          })}
          id={name}
          className={'w-full rounded-md border-none bg-input-gray p-4 text-base outline-none input-autofill'}
        >
          {isDefault && (
            <option key={'default'} value={''}>
              選択してください
            </option>
          )}
          {options.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
    </FieldWrapper>
  );
};
