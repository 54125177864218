import { Link } from '@remix-run/react';
import type { FunctionComponent } from 'react';
import styles from '~/styles/page/selling/staff-section.module.css';

export const StaffSection: FunctionComponent = () => {
  return (
    <div>
      <p className={styles.title}>バディカダイレクトのスタッフ</p>
      <p className={styles.caption}>あなたの愛車を査定するスタッフ達</p>

      <ul className={styles.staff}>
        <li className={styles.item}>
          <Link to='/member/yusa' className={styles.anchor}>
            <div className={styles.image}>
              <img src={'/images/members/yusa/list.jpg'} alt='yusa' />
            </div>
            <div className={styles.inner}>
              <p className={styles.name}>由佐 朋也</p>
              <p className={styles.text}>
                BUDDICAの創業メンバー。お客様の未来を見据え、全国のお客様に「愛車」の真価を伝える査定を行います。
              </p>
              <p className={styles.link}>詳細を見る</p>
            </div>
          </Link>
        </li>

        <li className={styles.item}>
          <Link to='/member/kurita' className={styles.anchor}>
            <div className={styles.image}>
              <img src={'/images/members/kurita/list.jpg'} alt='kurita' />
            </div>
            <div className={styles.inner}>
              <p className={styles.name}>栗田 政浩</p>
              <p className={styles.text}>お客様の愛車が正当な評価を受けるよう、心を込めて査定をサポートします！</p>
              <p className={styles.link}>詳細を見る</p>
            </div>
          </Link>
        </li>

        <li className={styles.item}>
          <Link to='/member/hasegawa' className={styles.anchor}>
            <div className={styles.image}>
              <img src={'/images/members/hasegawa/list.jpg'} alt='hasegawa' />
            </div>
            <div className={styles.inner}>
              <p className={styles.name}>長谷川 奨</p>
              <p className={styles.text}>
                お客様の理想を深く理解するため、熱心にお聞きします！そして、その理想に基づく最適な査定を心がけます。
              </p>
              <p className={styles.link}>詳細を見る</p>
            </div>
          </Link>
        </li>

        <li className={styles.item}>
          <Link to='/member/midorikawa' className={styles.anchor}>
            <div className={styles.image}>
              <img src={'/images/members/midorikawa/list.jpg'} alt='midorikawa' />
            </div>
            <div className={styles.inner}>
              <p className={styles.name}>緑川 凱久</p>
              <p className={styles.text}>
                あなたの愛車の旅を長期にわたってサポートする覚悟で、どこよりも寄り添い、丁寧な査定を心がけます。
              </p>
              <p className={styles.link}>詳細を見る</p>
            </div>
          </Link>
        </li>
      </ul>

      <div className={styles['button-wrapper']}>
        <Link to='/members/' className={styles.button}>
          もっと見る
        </Link>
      </div>
    </div>);

};