import { queryParamStringify } from '~/utils/url-param';
import { useNewSearchFormContext } from './useNewSearchForm';
import { useNavigate } from '@remix-run/react';
import { useSearchManage } from './useSearchManage';

export const NewSearchSubmitButton = () => {
  const form = useNewSearchFormContext();
  const navigate = useNavigate();
  const { setSearch, closeSearchModal } = useSearchManage();
  return (
    <button
      className='rounded-lg border-0 bg-gradient-to-r from-[#FC7900] to-[#EC1A1A] px-4 py-3 text-white'
      onClick={form.handleSubmit((data) => {
        queryParamStringify({ params: data, includeQuestion: true });
        setSearch(data);
        closeSearchModal();
        navigate(`/search${queryParamStringify({ params: data, includeQuestion: true })}`);
      })}
    >
      検索
    </button>
  );
};
