import type { VariantProps } from 'tailwind-variants';
import { tv } from 'tailwind-variants';

const buttonStyles = tv({
  base: 'box-border flex h-[50px] w-full cursor-pointer items-center justify-center gap-2 rounded-md px-4 text-lg font-bold outline-none',
  variants: {
    variant: {
      outline: 'border-2 bg-transparent',
      contained: 'text-white'
    },
    disable: {
      true: 'pointer-events-none opacity-40'
    },
    colorScheme: {
      success: '',
      danger: '',
      main: ''
    }
  },
  compoundVariants: [
    {
      variant: 'contained',
      colorScheme: 'success',
      class: 'bg-line-color'
    },
    {
      variant: 'outline',
      colorScheme: 'success',
      class: 'border-line-color text-line-color'
    },
    {
      variant: 'contained',
      colorScheme: 'danger',
      class: 'bg-red-500'
    },
    {
      variant: 'outline',
      colorScheme: 'danger',
      class: 'border-red-500 text-red-500'
    },
    {
      variant: 'contained',
      colorScheme: 'main',
      class: 'bg-main-color'
    },
    {
      variant: 'outline',
      colorScheme: 'main',
      class: 'border-main-color text-main-color'
    }
  ],
  defaultVariants: {
    variant: 'outline',
    colorScheme: 'success'
  }
});

type ButtonVariants = VariantProps<typeof buttonStyles>;

type Props = {
  handleClick?: () => void;
  text: React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
} & ButtonVariants;

export const SubmitButton = ({ handleClick, text, variant, colorScheme, disable, className, icon }: Props) => {
  return (
    <button className={buttonStyles({ variant, colorScheme, disable, className })} onClick={handleClick}>
      {icon}
      <span>{text}</span>
    </button>
  );
};
