import { searchCreateSchema, useSearchManageStore } from '~/store/search-store';
import { useShallow } from 'zustand/shallow';
import { useEffect } from 'react';
import queryString from 'query-string';
import { safeParser } from '~/utils/parser';

export const useSearchManage = () => {
  const searchManage = useSearchManageStore(
    useShallow((state) => ({
      search: state.search,
      setSearch: state.setSearch,
      closeSearchModal: state.closeSearchModal,
      setCloseSearchModal: state.setCloseSearchModal
    }))
  );
  const { setSearch } = searchManage;
  useEffect(() => {
    const searchParams = safeParser(searchCreateSchema, queryString.parse(location.search));
    setSearch(searchParams || { bodyType: null });
  }, [setSearch]);

  return {
    ...searchManage
  };
};
