import type { UseFormRegisterReturn } from 'react-hook-form';

type Props<U extends string> = {
  register: UseFormRegisterReturn<U>;
  id: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const Input = <T extends string>({ register, id, ...rest }: Props<T>) => {
  return (
    <input
      {...register}
      {...rest}
      id={id}
      className='w-full rounded-md border-none bg-input-gray p-4 text-base outline-none input-autofill'
    />
  );
};
