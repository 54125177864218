import type { FunctionComponent, ReactNode } from 'react';
import { useState } from 'react';
import styles from '~/styles/page/selling/faq.module.css';
import { ConversionButton } from './ConversionButton';

type FapItemProps = {
  title: string;
  text: string | ReactNode;
};

const FaqItem: FunctionComponent<FapItemProps> = ({ title, text }) => {
  const [open, setOpen] = useState(false);

  return (
    <li className={styles.item} onClick={() => setOpen(!open)}>
      <div className={styles.head}>
        <h3 className={styles['item-title']}>{title}</h3>
      </div>
      {open &&
      <div className={styles.body}>
          <p className={styles.text}>{text}</p>
        </div>
      }
    </li>);

};

export const Faq: FunctionComponent = () => {
  return (
    <div>
      <p className={styles.title}>よくあるご質問</p>
      <p className={styles.caption}>ご質問いただく内容をまとめました</p>

      <ul className={styles.faq}>
        <FaqItem
          title='電話やしつこいLINEでの連絡はきませんか？'
          text='当社は新しいクルマの販売購入体験を目指しているので、断りのない営業電話などは行いません。' />

        <FaqItem
          title='中古車査定の申し込みに費用はかかりますか？'
          text='当社の中古車査定サービスは完全に無料でご利用いただけます。申し込みから査定結果の受け取りまで、一切の費用は発生いたしません。' />

        <FaqItem
          title='査定の際、どのような点をチェックされますか？'
          text='査定では、車の外装や内装の状態、走行距離、年式、メンテナンスの履歴、修復歴の有無など、多岐にわたるポイントをチェックします。これらの情報を総合的に評価して、最終的な査定額を算出いたします。' />

        <FaqItem
          title='査定結果に納得がいかない場合、交渉は可能ですか？'
          text='はい、査定結果にご不満がある場合は、お気軽にご相談ください。当社では、ご提示した車両売却価格について理由をしっかり説明させていただきます。' />

        <FaqItem
          title='査定後、必ず車を売却しなければなりませんか？'
          text='いいえ、査定後に車を売却するかどうかはお客様の自由です。査定結果を参考に、他社に見積もりを出していただいても構いません。ご自身で最適な判断をしていただくことをお勧めします。' />

        <FaqItem
          title='査定から売却までの流れを教えてください。'
          text='まずオンラインフォームで査定の申し込みをしていただきます。その後、専門の査定士がお車の写真を確認し、査定額をご提示します。査定額にご納得いただけた場合、必要書類の準備を経て、正式に車の売却手続きを行います。' />

        <FaqItem
          title='車検が切れてる車も売却できますか？'
          text='はい、大丈夫です。車検切れの場合は陸送会社がご指定の場所まで車両を引き取りに伺います。' />

        <FaqItem
          title='ローンが残ってる車両も売却できますか？'
          text='はい、可能です。買取金額の中から一括でバディカから返済します。
          金額が足りない場合は、必要な金額を振り込んでいただきます。' />


        <FaqItem
          title='事故修復歴ある車でも売却できますか？'
          text='もちろん可能です。年式や走行距離、事故歴がある車に関しても買取可能です。' />

      </ul>

      <div className={styles['bottom-button']}>
        <ConversionButton text='無料：愛車査定はこちら' />
      </div>
    </div>);

};