import { useRef, useEffect } from 'react';
import { useShallow } from 'zustand/shallow';
import { useTrackingManageStore } from '~/store/tracking-store';

export const useTrackingManager = () => {
  const { setTracking, tracking, setTrackingVia } = useTrackingManageStore(
    useShallow((state) => ({
      setTracking: state.setTracking,
      tracking: state.tracking,
      setTrackingVia: state.setTrackingVia
    }))
  );

  const trackingRef = useRef(tracking);

  useEffect(() => {
    trackingRef.current = tracking;
  }, [tracking]);

  return {
    setTracking,
    trackingRef,
    tracking,
    setTrackingVia
  };
};
